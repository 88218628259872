import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import Title from './Title'



function Cta() {
  return (
    <div className="section" id="cta_block">
      <div className="container">
        <div className="s-50"></div>
        <div className="flex aic jcc">
          <div>
            <Title
              type="title white"
              text="Save cash and time"
            />
            <div className="container text-center">
              <div className="cta">
                <p className="subtitle white">We provide a seamless integration that helps you manage your procurement, accounting, and drawings all in one place. With Cloudy Construction, you'll save money and time every day.</p>
              </div>
              <AnchorLink href="#contact_block" className='btn btn--premium bg-white btn--big'>Connect Now</AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta
