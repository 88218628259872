import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Title from './Title'

import f1 from '../assets/svg/icons/f001.svg'
import f2 from '../assets/svg/icons/f002.svg'
import f3 from '../assets/svg/icons/f003.svg'

function Features() {
  return (
    <div className="section" id="feature_block">
      <Title
        type="title medium"
        text="Cloudy's Cross-Functional Solution"
      />
      <div className="container">
        <h2 className="subtitle w-60 ma text-center medium">Compliance is ensured by the professional team of experts at Cloudy who keep your business updated with the latest international standards
        </h2>
        <div className="s-40"></div>
        <div className="flex jcsa w-90 ma">
          <div className="w-30 text-center">
            <h3 className="subtitle"><img className='ma my-5' src={f1} alt="icon" /><span>Clear and easy management</span></h3>
            <p className="text medium">Build to Suite is a one-stop platform for all your construction needs. Just log in and manage all your projects - drawing management, compliance, procurement, and accounting will be done instantly.</p>
          </div>
          <div className="w-30 text-center">
            <h3 className="subtitle"><img className='ma my-5' src={f2} alt="icon" /><span>Bidding process made easy</span></h3>
            <p className="text medium">Save up to 40% by using Build to Suite's automated bidding system to ensure that you'll win the project every time. We do the heavy lifting to make sure bids are perfect every time!</p>
          </div>
          <div className="w-30 text-center">
            <h3 className="subtitle"><img className='ma my-5' src={f3} alt="icon" /><span>Drawings done right</span></h3>
            <p className="text medium">Build to Suite manages drawings too! Drawings are generated with all the necessary information to complete your project - including compliance, budgets, schedules, etc., so you can get back to work without having to worry about all that paperwork.</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="text-center">
          <AnchorLink href="#contact_block" className='btn btn--big'>Connect Now</AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default Features
