import React from 'react'
import pic from '../assets/img/content/intro-pic.png'

function Intro() {
  return (
    <div className="intro">
      <div className="container">
        <div className="flex rowrap jcsa aic w-100 h-90">
          <div className="w-50 text-left">
            <h2 className="intro__subtitle mb-2">Bidding process made easy</h2>
            <h1 className="intro__title">Cloudy is the only construction company software you will ever need</h1>
            <div className="s-20"></div>
            <p className="intro__text w-80 text-left">Cloudy Construction is a tech company that helps you to manage your construction work from top to bottom. Invest in Cloudy and increase your profits</p>
          </div>
          <div className="w-40 flex jcfe">
            <img src={pic} alt="pic" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
