import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../logo.svg'

import partner001 from '../assets/img/partners/partners001.png'
import partner002 from '../assets/img/partners/partners002.png'
import partner003 from '../assets/img/partners/partners003.png'
import partner004 from '../assets/img/partners/partners004.png'
import partner005 from '../assets/img/partners/partners005.png'
import partner006 from '../assets/img/partners/partners006.png'

function Footer() {
  return (
    <footer className="footer" id="footer_block">
      <div className="flex jcc aic w-100 py-5 bg-blue">
        <div className="flex jcsb aic w-90 ma p-5">
          <img src={partner001} className="partners__image" alt="partners" />
          <img src={partner002} className="partners__image" alt="partners" />
          <img src={partner003} className="partners__image" alt="partners" />
          <img src={partner004} className="partners__image" alt="partners" />
          <img src={partner005} className="partners__image" alt="partners" />
          <img src={partner006} className="partners__image" alt="partners" />
        </div>
      </div>
      <div className="py-5">
        <div className="container text-center">
          <div className="flex jcsb rowrap w-90 ma my-5">
            <div className="s-40"></div>
            <div className="w-50">
              <AnchorLink href="#root">
                <img src={logo} alt="logo" className='w-10' />
              </AnchorLink>
              <div className="s-10"></div>
              <p className="text c-white text-left w-70">Cloudy Construction is a tech company that helps you to manage your construction work from top to bottom. Invest in Cloudy and increase your profits</p>
            </div>
            <div className="w-4">
              <p className='text c-white text-left'>
                <AnchorLink href="#contact_block">Head Office</AnchorLink>
              </p>
              <p className='text c-white text-left'>
                <AnchorLink href="#footer_block" className='text c-white text-left'>UK Office</AnchorLink>
              </p>
              <p className='text c-white text-left'>
                <AnchorLink href="#footer_block" className='text c-white text-left'>EU Office</AnchorLink>
              </p>
            </div>
            <div className="w-4">
              <p className='text c-white text-left'><strong>Head Office:</strong></p>
              <p className='text c-white text-left'>Cropsey Ave. 128 ap. 16</p>
              <p className='text c-white text-left'>US, NY, Brooklyn</p>
            </div>
          </div>
          <div className="s-30"></div>
        </div>
        <div className="f-bottom" style={{ borderTop: '1px solid #115577' }}>
          <div className="flex w-90 ma">
            <span className="text text-left white w-50">
              <AnchorLink href="#root" className='text c-lite text-right'>Built to Suite</AnchorLink> - &copy;2022. All Rights Reserved</span>
            <div className="w-50 flex jcsa" >
              <AnchorLink href="#footer_block" className='text c-lite text-right'>Privacy Policy</AnchorLink>
              <AnchorLink href="#footer_block" className='text c-lite text-right'>Terms of Use</AnchorLink>
              <AnchorLink href="#contact_block" className='text c-lite text-right'>Support</AnchorLink>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer
