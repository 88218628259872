import React from 'react'
import { HiGiftTop, HiAcademicCap, HiCpuChip } from "react-icons/hi2"
import Title from './Title'



import Star from '../assets/svg/round-star.svg'
import MacBook from '../assets/img/content/macbook.png'

function About() {
  return (
    <div className="section" id="about_block">
      <Title
        type="title theme"
        text="About Us"
      />
      <div className="container">
        <div className="about">
          <h2 className="subtitle dark w-80 ma text-center">We provide a seamless integration that helps you manage your procurement, accounting, and drawings all in one place. With Built to Suite, you'll save money and time every day. You could be waiting weeks, months, or even years for this service, when Cloudy Construction can do the same task within just a few days!
          </h2>
        </div>
        <div className="s-80"></div>
        <div className="flex w-90 rowrap ma aic">
          <div className="w-40 ml-5">
            <div className="flex aic bg-premium r-40 py-3 px-4">
              <span className="a-icon small bg-white r-50 ra">
                <HiGiftTop className='c-premium' size={24} />
              </span>
              <span className="text icon medium"><div className="text-white pl-2">Cloudy offers a highly secured platform that caters to your every need</div></span>
            </div>
            <div className="s-20"></div>
            <div className="flex aic bg-marine r-40 py-3 px-4">
              <span className="a-icon small bg-white r-50 ra">
                <HiCpuChip className='c-marine' size={24} />
              </span>
              <span className="text icon medium"><div className="text-white pl-2">Bidding process is automated so you can spend more time</div></span>
            </div>
            <div className="s-20"></div>
            <div className="flex aic bg-blue r-40 py-3 px-4">
              <span className="a-icon small bg-white r-50 ra">
                <HiAcademicCap className='c-blue' size={24} />
              </span>
              <span className="text icon medium"><div className="text-white pl-2">Compliance is ensured by the professional team of experts</div></span>
            </div>
          </div>
          <div className="w-50 flex ma aic">
            <img src={MacBook} alt="dashboard" />
          </div>
        </div>
        <div className="s-80"></div>
        <div className="flex w-90 ma ml-5">
          <div className="w-50 ma m-5 p-5 bg-white shadow">
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Estimating – Estimate labor and material costs.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Document and Drawing Management – Store, manage and share drawings, documents, contracts and blueprints.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Reporting – Generate real-time reports on the project status.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Project Accounting – Track project costs, budgets, and billings.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Collaboration – Real-time communication and file sharing with project teams.</p></div>
          </div>
          <div className="w-50 ma m-5 p-5 bg-white shadow">
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Construction Project Management – Manage and track projects, budgets, and timelines.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Bid Management – Create, manage, and submit bids.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Procurement – Automate purchase orders and supply chain management.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Safety and Compliance – Track safety compliance and regulations.</p></div>
            <div className="pl-3 flex w-100 aic jcsb"><img src={Star} alt="icon" /><p className='text medium text-left w-90'>Inspection Management – Schedule and track inspections.</p></div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default About
