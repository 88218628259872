import React from 'react'
import Title from './Title'
import pic1 from '../assets/img/product/product001.jpg'
import pic2 from '../assets/img/product/product002.jpg'
import pic3 from '../assets/img/product/product003.jpg'
import pic4 from '../assets/img/product/product004.jpg'
import pic5 from '../assets/img/product/product005.jpg'
import pic6 from '../assets/img/product/product006.jpg'

function Cases() {

  const slides = [
    {
      id: 1,
      pic: pic1,
      text: `Location: Madrid, Spain |
      Employees: 190,500 |
      Revenue: €39 billion |
      Founded: 1997 |
      Notable Project: Torre Glòries`,
      title: 'ACS Actividades de Construcción y Servicios S.A.'
    },
    {
      id: 2,
      pic: pic2,
      text: `Location: Essen, Germany |
      Employees: 47,000 |
      Revenue: €21.1 billion |
      Founded: 1874 |
      Notable Project: Bosphorus Bridge`,
      title: 'Hochtief Aktiengesellschaft'
    },
    {
      id: 3,
      pic: pic3,
      text: `Location: Rueil Malmaison, France |
      Employees: 222,000 |
      Revenue: €48.05 billion |
      Founded: 1899 |
      Notable Project: Louvre Entrance`,
      title: 'VINCI'
    },
    {
      id: 4,
      pic: pic4,
      text: `Location: Beijing, China |
      Employees: 147,000 |
      Revenue: $79.8 billion |
      Founded: 2005 |
      Notable Project: Yangtze River Highway`,
      title: 'China Communications Construction Group Ltd.'
    },
    {
      id: 5,
      pic: pic5,
      text: `Location: Paris, France |
      Employees: 130,500 |
      Revenue: €35.55 billion |
      Founded: 1952 |
      Notable Project: Musée d’Orsay`,
      title: 'Bouygues'
    },
    {
      id: 6,
      pic: pic6,
      text: `Location: Vienna, Austria |
      Employees: 74,000 |
      Revenue: €15.7 billion |
      Founded: 1835 |
      Notable Project: Alte Weser Lighthouse`,
      title: 'Strabag'
    }
  ]

  return (
    <div className="section" id="cases_block">
      <Title
        type="title"
        text="We are trusted by the best"
      />
      <div className="container">
        <h2 className="subtitle w-70 ma text-center medium">Construction companies want to focus on what they do best - building homes, offices, or other buildings. And they want their accounting to be done as effortlessly as possible. Unfortunately, construction company software is usually too complicated to use or takes up too much time to implement. Cloudy Construction simplifies things so you can focus on what's important.
        </h2>
        <div className="s-40"></div>
        <div className="w-90 ma">
          {slides.map(screen => (
            <>
              <div className='flex w-100 p-5 bg-white r-16 shadow-sm'>
                <div className="w-50">
                  <img
                    className='pic r-8'
                    src={screen.pic}
                    alt={'pic'}
                  />
                </div>
                <div className="w-40 pl-5 ml-5">
                  <h3 className="subtitle text-left mt-5"><strong>{screen.title}</strong></h3>
                  <p className="text">{screen.text}</p>
                </div>
              </div>
              <div className="s-20"></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cases
