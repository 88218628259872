import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../logo.svg'

function Menu(props) {
  return (
    <div className="hb-header">
      <h1 className="hb-logo">
        <AnchorLink className="hb-logo__link scroll" href="#root">
          <img src={logo} className="hb-logo__img" alt="HeyBoss" />
          <h1 className="hb-logo__text"><strong>Built to Suite</strong></h1>
        </AnchorLink>
      </h1>
      <div className={`hb-menu ${props.mobile}`}>
        <AnchorLink className="hb-menu__link" href="#about_block">About</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#feature_block">Features</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#cases_block">Partners</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#contact_block">Support</AnchorLink>
        <AnchorLink className="btn" href="#contact_block">Start Now</AnchorLink>
      </div>
    </div>
  );
}

export default Menu
